// src/components/Header.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import "./headerStyle.css";

const Header = () => {
  const { currentUser, showForm, setShowForm, setShowAuthForm } =
    useAppContext();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleRegisterClick = () => {
    setShowAuthForm(true);
    setIsMobileMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="header-container">
        <div className="header-left">
          <div className="mobile-header">
            <button className="hamburger-menu" onClick={toggleMobileMenu}>
              ☰
            </button>
            <Link to="/" className="header-title-link">
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/pundit_logo_bw.svg`}
                alt="Pundit"
                className="pundit-icon"
              />
            </Link>
          </div>
          <div className="desktop-header">
            <nav className="desktop-nav">
              <ul className="desktop-menu">
                <li>
                  <span>Season Ratings</span>
                  <ul className="submenu">
                    <li>
                      <Link to="/team-ratings">Team Ratings</Link>
                    </li>
                    <li>
                      <Link to="/player-ratings">Player Ratings</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <span>Leaderboard</span>
                  <ul className="submenu">
                    <li>
                      <Link to="/team-favorites">Favorite Teams</Link>
                    </li>
                    <li>
                      <Link to="/funniest-users">Funniest Users</Link>
                    </li>
                    <li>
                      <Link to="/insightful-users">Most Insightful Users</Link>
                    </li>
                    <li>
                      <Link to="/prolific-users">Most Prolific Users</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/matches">Matches</Link>
                </li>
                <li>
                  <span>EAFC 25 Ratings</span>
                  <ul className="submenu">
                    <li>
                      <Link to="/EA-FC25-player-ratings-form">
                        Submit Your Player Ratings
                      </Link>
                    </li>
                    <li>
                      <Link to="/EA-FC25-player-ratings">
                        Community Player Ratings
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="header-right">
          {currentUser ? (
            <Link to="/userprofile" className="user-profile-link">
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/user.svg`}
                alt="User"
                className="user-profile-icon"
              />
            </Link>
          ) : (
            <button className="add-review-button" onClick={handleRegisterClick}>
              Register
            </button>
          )}
        </div>
      </div>
      {isMobileMenuOpen && (
        <nav className="nav open">
          <ul className="mobile-menu">
            <li>
              <span>Season Ratings</span>
              <ul>
                <li>
                  <Link to="/player-ratings" onClick={toggleMobileMenu}>
                    Player Ratings
                  </Link>
                </li>
                <li>
                  <Link to="/team-ratings" onClick={toggleMobileMenu}>
                    Team Ratings
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/matches" onClick={toggleMobileMenu}>
                Matches
              </Link>
            </li>
            <li>
              <span>Leaderboard</span>
              <ul>
                <li>
                  <Link to="/team-favorites" onClick={toggleMobileMenu}>
                    Favorite Teams
                  </Link>
                </li>
                <li>
                  <Link to="/funniest-users" onClick={toggleMobileMenu}>
                    Funniest Users
                  </Link>
                </li>
                <li>
                  <Link to="/insightful-users" onClick={toggleMobileMenu}>
                    Most Insightful Users
                  </Link>
                </li>
                <li>
                  <Link to="/prolific-users" onClick={toggleMobileMenu}>
                    Most Prolific Users
                  </Link>
                </li>
              </ul>
              <li>
                <span>EAFC 25 Ratings</span>
                <ul>
                  <li>
                    <Link
                      to="/EA-FC25-player-ratings-form"
                      onClick={toggleMobileMenu}
                    >
                      Submit Your Player Ratings
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/EA-FC25-player-ratings"
                      onClick={toggleMobileMenu}
                    >
                      Community Player Ratings
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/about-us" onClick={toggleMobileMenu}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/terms" onClick={toggleMobileMenu}>
                  Terms of Use
                </Link>
              </li>
              <li>
                <Link to="/privacy" onClick={toggleMobileMenu}>
                  Privacy Policy
                </Link>
              </li>
            </li>
            {currentUser ? (
              <li>
                <Link to="/userprofile" onClick={toggleMobileMenu}>
                  Your Profile
                </Link>
              </li>
            ) : (
              <li>
                <button
                  className="register-button"
                  onClick={handleRegisterClick}
                >
                  Sign In / Register
                </button>
              </li>
            )}
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
