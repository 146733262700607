import React from "react";
import { getPillColor } from "../utils/helpers";

const Lineup = ({ data }) => {
  console.log(data);
  return (
    <div className="lineup-container">
      {data.map((team, index) => (
        <div key={index} className="team">
          <div className="team-info">
            <div className="pundit-logo">
              <img
                src={`${process.env.PUBLIC_URL}/images/icons/pundit_logo_bw.svg`}
                alt="Pundit"
                className="pundit-icon-lineup"
              />{" "}
            </div>
            <div className="game-logo">
              <img
                src={team.teamLogo}
                alt={team.teamName}
                className="team-logo"
              />{" "}
              <h1 className="score">{team.score}</h1>
              <img
                src={team.opponentLogo}
                alt={team.opponentName}
                className="team-logo"
              />
            </div>
            <div className="username">
              <h3>Player Ratings By:</h3>
              <br />
              <h1>{team.username}</h1>
            </div>
          </div>
          <div className="lineup-formation">
            {/* Centered Nick Pope's card */}
            <div className="lineup-row lineup-row-1">
              <div className="lineup-player">
                <div className="lineup-player-info">
                  <img
                    src={team.players[0].photo}
                    alt={team.players[0].name}
                    className="player-image"
                  />
                  <h3 className="lineup-player-name">{team.players[0].name}</h3>
                  <div
                    className={`lineup-score-pill ${getPillColor(
                      team.players[0].rating
                    )}`}
                  >
                    {team.players[0].rating}
                  </div>
                </div>
                <div className="lineup-review">
                  <p className="lineup-review-text">{team.players[0].review}</p>
                </div>
              </div>
            </div>

            {/* Adjusted the layout to create a 4-3-3 formation */}
            <div className="lineup-row lineup-row-2">
              {team.players.slice(1, 5).map((player, idx) => (
                <div key={idx} className="lineup-player">
                  <div className="lineup-player-info">
                    <img
                      src={player.photo}
                      alt={player.name}
                      className="player-image"
                    />
                    <h3 className="lineup-player-name">{player.name}</h3>
                    <div
                      className={`lineup-score-pill ${getPillColor(
                        player.rating
                      )}`}
                    >
                      {player.rating}
                    </div>
                  </div>
                  <div className="lineup-review">
                    <p className="lineup-review-text">{player.review}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="lineup-row lineup-row-3">
              {team.players.slice(5, 8).map((player, idx) => (
                <div key={idx} className="lineup-player">
                  <div className="lineup-player-info">
                    <img
                      src={player.photo}
                      alt={player.name}
                      className="player-image"
                    />
                    <h3 className="lineup-player-name">{player.name}</h3>
                    <div
                      className={`lineup-score-pill ${getPillColor(
                        player.rating
                      )}`}
                    >
                      {player.rating}
                    </div>
                  </div>
                  <div className="lineup-review">
                    <p className="lineup-review-text">{player.review}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="lineup-row lineup-row-4">
              {team.players.slice(8, 11).map((player, idx) => (
                <div key={idx} className="lineup-player">
                  <div className="lineup-player-info">
                    <img
                      src={player.photo}
                      alt={player.name}
                      className="player-image"
                    />
                    <h3 className="lineup-player-name">{player.name}</h3>
                    <div
                      className={`lineup-score-pill ${getPillColor(
                        player.rating
                      )}`}
                    >
                      {player.rating}
                    </div>
                  </div>
                  <div className="lineup-review">
                    <p className="lineup-review-text">{player.review}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Lineup;
