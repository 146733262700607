import React from "react";
import { Helmet } from "react-helmet"; // {2023-11-21 17:30} Import Helmet for SEO

const Privacy = () => {
  return (
    <div className="privacy-container">
      <Helmet>
        <title>Privacy Policy | Pundit.World</title>{" "}
        {/* {2023-11-21 17:30} Added title tag for SEO */}
        <meta
          name="description"
          content="Read the Privacy Policy of Pundit.World to understand how we collect, use, and protect your personal data." // {2023-11-21 17:30} Added meta description for SEO
        />
        <link
          rel="canonical"
          href="https://www.pundit.world/privacy-policy" // {2023-11-21 17:30} Added canonical link for SEO
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "Privacy Policy",
            description:
              "Read the Privacy Policy of Pundit.World to understand how we collect, use, and protect your personal data.",
            url: "https://www.pundit.world/privacy-policy",
          })}
        </script>
      </Helmet>
      <h1>Privacy Policy of Pundit.World</h1>
      <p>
        This Privacy Policy outlines how pundit.world collects, uses, and
        protects your Personal Data. By using our services, you agree to the
        terms outlined below.
      </p>

      <h2>1. Types of Data Collected</h2>
      <ul>
        <li>
          <strong>Personal Data:</strong> We collect email addresses and
          information about your favorite soccer team.
        </li>
        <li>
          <strong>Cookies and Tracking:</strong> We use cookies and Google
          Analytics to monitor activity on our site.
        </li>
      </ul>

      <h2>2. Use of Collected Data</h2>
      <ul>
        <li>
          <strong>User Authentication:</strong> Your email and favorite team
          information are used to personalize your experience and authenticate
          your account.
        </li>
        <li>
          <strong>Analytics:</strong> Google Analytics helps us understand how
          users interact with our site to improve services.
        </li>
        <li>
          <strong>Marketing:</strong> We may use your data to inform you about
          relevant updates, features, or promotions.
        </li>
      </ul>

      <h2>3. User Rights</h2>
      <ul>
        <li>
          <strong>Data Deletion:</strong> You can delete your data by using the
          delete account button on your User Profile page. Once deleted, all
          associated data is permanently removed from our systems.
        </li>
        <li>
          <strong>Opt-Out:</strong> Users can opt out of Google Analytics
          tracking by using the browser add-on provided by Google.
        </li>
      </ul>

      <h2>4. Data Sharing</h2>
      <p>
        <strong>Third-Party Services:</strong> Your data is only shared with
        Google Analytics for the purposes mentioned above. We do not sell or
        share your data with any other third parties.
      </p>

      <h2>5. Data Security</h2>
      <p>
        <strong>Protection Measures:</strong> We use encryption and access
        controls, as provided by Supabase, to protect your data.
      </p>

      <h2>6. International Data Transfers</h2>
      <p>
        <strong>No Transfers:</strong> We do not transfer your data to other
        countries.
      </p>

      <h2>7. Children's Privacy</h2>
      <p>
        <strong>No Age Restrictions:</strong> Our services do not have specific
        measures for protecting children's privacy and are not restricted by
        age.
      </p>

      <h2>8. Legal Compliance</h2>
      <p>
        <strong>GDPR Compliance:</strong> We comply with the General Data
        Protection Regulation (GDPR). A consent banner is displayed when users
        first visit the site.
      </p>

      <h2>9. Contact Information</h2>
      <p>
        <strong>Data Protection Contact:</strong> If you have any questions or
        concerns about your privacy, please contact us at{" "}
        <a href="mailto:pundit.sports.home+Privacy@gmail.com">
          pundit.sports.home+Privacy@gmail.com
        </a>
        .
      </p>

      <h2>10. Changes to This Privacy Policy</h2>
      <p>
        <strong>Updates:</strong> We will notify you of any changes to this
        policy via email.
      </p>
    </div>
  );
};

export default Privacy;
