// src/App.js
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppProvider } from "./context/AppContext";
import AppContent from "./AppContent";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";

// Initialize Google Analytics
ReactGA.initialize("G-XMKQK04Q0X");

// Initialize Google Tag Manager
TagManager.initialize({ gtmId: "GTM-NN2MSKBF" });

function App() {
  return (
    <Router>
      <AppProvider>
        <AppContent />
      </AppProvider>
    </Router>
  );
}

export default App;
