// src/hooks/useDataFetching.js
import { useState, useEffect, useCallback } from "react";
import supabase from "../api/supabase";

const REVIEWS_PER_PAGE = 20; // Adjust this value as needed

const useDataFetching = () => {
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortType, setSortType] = useState("created_at");
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  // {2023-11-22 12:15} Updated fetchReviews to include sortType in its dependency array
  const fetchReviews = useCallback(
    async (page = 0) => {
      setIsLoading(true);
      try {
        const {
          data: reviewsData,
          error,
          count,
        } = await supabase
          .from("reviews_table_soccer")
          .select(
            `
          *,
          players_table_soccer (id, player_name, photo)
        `,
            { count: "exact" }
          )
          .not("review_text", "is", null)
          .not("review_text", "eq", "")
          .order(sortType, { ascending: false })
          .range(page * REVIEWS_PER_PAGE, (page + 1) * REVIEWS_PER_PAGE - 1);

        if (error) throw error;

        // {2023-08-21 - 15:31} Fetch team associations for all players in the reviews
        const playerIds = reviewsData.map((review) => review.player_id);
        const { data: teamAssociations, error: teamAssociationsError } =
          await supabase
            .from("player_team_associations")
            .select("player_id, team_id")
            .in("player_id", playerIds);

        console.log("teamAssociations", teamAssociations);

        if (teamAssociationsError) throw teamAssociationsError;

        // {2023-08-21 - 15:32} Fetch game details for all reviews
        const gameIds = reviewsData.map((review) => review.game_id);
        const { data: gamesData, error: gamesError } = await supabase
          .from("games_table_soccer")
          .select(
            "id, date,home_team_id, away_team_id, home_team_name, away_team_name"
          )
          .in("id", gameIds);

        console.log("gamesData", gamesData);
        console.log("gamesData", gamesData[0]);
        console.log("gamesData.date", gamesData[0].date);

        if (gamesError) throw gamesError;

        // {2023-08-21 - 15:33} Process and combine the data
        const transformedData = reviewsData.map((review) => {
          const teamAssociation = teamAssociations.find(
            (ta) => ta.player_id === review.player_id
          );
          const gameDetails = gamesData.find(
            (game) => game.id === review.game_id
          );

          let playerTeam = null;
          let opposingTeam = null;
          let formattedDate = null;

          if (gameDetails && teamAssociation) {
            if (gameDetails.home_team_id === teamAssociation.team_id) {
              playerTeam = gameDetails.home_team_name;
              opposingTeam = gameDetails.away_team_name;
            } else if (gameDetails.away_team_id === teamAssociation.team_id) {
              playerTeam = gameDetails.away_team_name;
              opposingTeam = gameDetails.home_team_name;
            }
          }
          console.log("gameDetails.date", gameDetails.date);
          if (gameDetails.date) {
            const date = new Date(gameDetails.date);
            console.log("date", date);
            formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
              date.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}/${date.getFullYear()}`;
          }
          console.log("formattedDate", formattedDate);

          return {
            ...review,
            player: {
              id: review.players_table_soccer?.id,
              player_name: review.players_table_soccer?.player_name,
            },
            player_team: playerTeam,
            opposing_team: opposingTeam,
            game_date: formattedDate,
          };
        });

        // {2023-08-21 - 15:34} Update reviews state
        setReviews((prevReviews) =>
          page === 0 ? transformedData : [...prevReviews, ...transformedData]
        );
        setHasMore(count > (page + 1) * REVIEWS_PER_PAGE);
        setCurrentPage(page);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [sortType]
  ); // Include sortType in the dependency array

  const loadMoreReviews = useCallback(() => {
    if (!isLoading && hasMore) {
      fetchReviews(currentPage + 1);
    }
  }, [fetchReviews, isLoading, hasMore, currentPage]);

  // {2023-11-22 12:16} Added updateSortType function
  const updateSortType = useCallback(
    (newSortType) => {
      setSortType(newSortType);
      setCurrentPage(0);
      fetchReviews(0);
    },
    [fetchReviews]
  );

  useEffect(() => {
    fetchReviews(0);
  }, [fetchReviews]);

  return {
    reviews,
    setReviews,
    isLoading,
    fetchReviews,
    loadMoreReviews,
    hasMore,
    sortType,
    updateSortType, // {2023-11-22 12:17} Export updateSortType instead of setSortType
  };
};

export default useDataFetching;
