import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import Loader from "../components/Loader";
import { Helmet } from "react-helmet";
import "./team_ratings_style.css";

function TeamRatingsPage() {
  const [teamRatings, setTeamRatings] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState("Overall");
  const [selectedSeason, setSelectedSeason] = useState("2024-2025");
  const [currentPage, setCurrentPage] = useState(1);
  const [teamsPerPage] = useState(20);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { supabase, getPillColor } = useAppContext();

  useEffect(() => {
    const fetchTeamRatings = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const { data: ratingsData, error: ratingsError } = await supabase
          .from("team_review_averages_soccer")
          .select("*")
          .eq("average_season_year", selectedSeason);
        console.log(ratingsData);

        if (ratingsError) throw ratingsError;

        const { data: teamsData, error: teamsError } = await supabase
          .from("teams_table_soccer")
          .select("*")
          .eq("competition_name", "Premier League");
        console.log(teamsData);

        if (teamsError) throw teamsError;

        // {2023-11-21 16:30} - Updated data formatting to use competition_name from teams_table_soccer
        const formattedData = ratingsData.reduce((acc, rating) => {
          const team = teamsData.find((t) => t.id === rating.team_id);
          if (!team) return acc;

          if (!acc[rating.team_id]) {
            acc[rating.team_id] = {
              team_id: rating.team_id,
              team_name: team.team_name,
              team_logo: team.team_logo,
              competition_name: team.competition_name, // Use competition_name from teams_table_soccer
              overall_rating: 0,
              total_ratings: 0,
              competition_ratings: {},
            };
          }

          if (rating.competition_name === "Overall") {
            acc[rating.team_id].overall_rating = rating.average_rating;
            acc[rating.team_id].total_ratings = rating.number_of_ratings;
          } else {
            acc[rating.team_id].competition_ratings[rating.competition_name] = {
              average_rating: rating.average_rating,
              number_of_ratings: rating.number_of_ratings,
            };
          }
          return acc;
        }, {});

        setTeamRatings(Object.values(formattedData));

        // {2023-11-21 16:35} - Updated competitions list to use unique competition names from teams_table_soccer
        setCompetitions([
          "Overall",
          ...new Set(teamsData.map((team) => team.competition_name)),
        ]);
      } catch (error) {
        console.error("Error fetching team ratings:", error);
        setError("Failed to fetch team ratings. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeamRatings();
  }, [supabase, selectedSeason]);

  const sortedAndFilteredRatings = useMemo(() => {
    return teamRatings
      .filter(
        (team) =>
          selectedCompetition === "Overall" ||
          team.competition_name === selectedCompetition
      )
      .sort((a, b) => {
        const ratingA =
          selectedCompetition === "Overall"
            ? a.overall_rating
            : a.competition_ratings[selectedCompetition]?.average_rating || 0;
        const ratingB =
          selectedCompetition === "Overall"
            ? b.overall_rating
            : b.competition_ratings[selectedCompetition]?.average_rating || 0;
        return ratingB - ratingA; // Descending order
      });
  }, [teamRatings, selectedCompetition]);

  // Get current teams
  const indexOfLastTeam = currentPage * teamsPerPage;
  const indexOfFirstTeam = indexOfLastTeam - teamsPerPage;
  const currentTeams = sortedAndFilteredRatings.slice(
    indexOfFirstTeam,
    indexOfLastTeam
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (isLoading) return <Loader />;
  if (error) return <div className="error-message">{error}</div>;

  const PageTitle =
    selectedCompetition === "Overall" ? "Premier League" : selectedCompetition;

  console.log(currentTeams);

  return (
    <div className="team-ratings-page">
      <Helmet>
        <title>
          {PageTitle} Team Ratings for {selectedSeason} | Pundit
        </title>{" "}
        {/* {2023-11-21 16:40} Added title tag for SEO */}
        <meta
          name="description"
          content={`View ${PageTitle} team ratings for the ${selectedSeason} season. Filter by competition and see detailed ratings for each team.`} // {2023-11-21 16:40} Added meta description for SEO
        />
        <link
          rel="canonical"
          href={`https://www.pundit.world/team-ratings/${selectedSeason}`} // {2023-11-21 16:40} Added canonical link for SEO
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "Team Ratings",
            description: `View ${PageTitle} team ratings for the ${selectedSeason} season. Filter by competition and see detailed ratings for each team.`,
            url: `https://www.pundit.world/team-ratings/${selectedSeason}`,
          })}
        </script>
      </Helmet>
      <h1>Team Ratings</h1>
      <div className="competition-dropdown-container">
        <div className="filters-container">
          <select
            value={selectedSeason}
            onChange={(e) => setSelectedSeason(e.target.value)}
            className="season-dropdown"
          >
            <option value="2024-2025">2024-2025 Season</option>
          </select>
          <select
            value={selectedCompetition}
            onChange={(e) => setSelectedCompetition(e.target.value)}
            className="competition-dropdown"
          >
            {competitions.map((comp) => (
              <option key={comp} value={comp}>
                {comp}
              </option>
            ))}
          </select>
        </div>
      </div>
      {currentTeams.length > 0 ? (
        <>
          <table className="team-ratings-table">
            <thead className="header-row">
              <tr>
                <th>Team Name</th>
                <th>League</th>
                <th>Season Rating</th>
              </tr>
            </thead>
            <tbody>
              {currentTeams.map((team) => (
                <tr key={team.team_id}>
                  <td className="team-player-name">
                    <img
                      src={team.team_logo}
                      alt={`${team.team_name} logo`} // {2023-11-21 16:30} Added descriptive alt attribute for SEO
                      className="tr-team-logo"
                    />
                    <div className="tr-team-name">
                      <Link
                        to={`/team/${team.team_id}/${encodeURIComponent(
                          team.team_name
                        )}`}
                      >
                        {team.team_name}
                      </Link>
                    </div>
                  </td>
                  <td className="tr-league-info">
                    {/* {2023-11-21 16:30} - Updated to display competition_name from teams_table_soccer */}
                    <div>{team.competition_name}</div>
                  </td>
                  <td className="tr-rating-score">
                    <div
                      className={`team-ratings-score-pill ${getPillColor(
                        selectedCompetition === "Overall"
                          ? team.overall_rating
                          : team.competition_ratings[selectedCompetition]
                              ?.average_rating || 0
                      )}`}
                    >
                      {(selectedCompetition === "Overall"
                        ? team.overall_rating
                        : team.competition_ratings[selectedCompetition]
                            ?.average_rating || 0
                      ).toFixed(2)}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from(
              {
                length: Math.ceil(
                  sortedAndFilteredRatings.length / teamsPerPage
                ),
              },
              (_, i) => (
                <button
                  key={i}
                  onClick={() => paginate(i + 1)}
                  className={currentPage === i + 1 ? "active" : ""}
                >
                  {i + 1}
                </button>
              )
            )}
          </div>
        </>
      ) : (
        <p>No teams found for the selected criteria.</p>
      )}
    </div>
  );
}

export default TeamRatingsPage;
