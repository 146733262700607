import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import Privacy from "./Privacy";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>Links</h3>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/player-ratings">Player Ratings</Link>
            </li>
            <li>
              <Link to="/matches">Matches</Link>
            </li>
            <li>
              <Link to="/team-favorites">Team Favorites</Link>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Community</h3>
          <ul>
            <li>
              <a
                target="https://discord.com/channels/1273328258259357738/1273328258749829203"
                href="https://discord.com/channels/pundit_world1"
                rel="noopener noreferrer"
              >
                Discord Server
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/pundit_world1/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://x.com/Pundit_World"
                target="https://x.com/Pundit_World"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>About</h3>
          <ul>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms">Terms of Service</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 PUNDIT. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
