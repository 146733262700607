// src/api/teams.js
import supabase from "../api/supabase";

const API_KEY = process.env.REACT_APP_API_KEY;
const BASE_URL = "https://v3.football.api-sports.io";

export const fetchNbaTeams = async (leagueId, season) => {
  if (!leagueId || !season) {
    console.error("League and season are required");
    return [];
  }

  const API_URL = `${BASE_URL}/teams?league=${leagueId}&season=${season}`;

  try {
    const response = await fetch(API_URL, {
      method: "GET",
      headers: {
        "x-rapidapi-key": API_KEY,
        "x-rapidapi-host": "v3.football.api-sports.io",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    const formattedTeams = data.response.map((item) => ({
      id: item.team.id,
      name: item.team.name,
      key: item.team.code,
    }));

    console.log("Formatted Teams:", formattedTeams);
    return formattedTeams;
  } catch (error) {
    console.error("Failed to fetch teams:", error);
    return [];
  }
};

export const fetchTeams = async () => {
  try {
    const { data, error } = await supabase
      .from("teams_table_soccer")
      .select("id, team_name, competition_name, competition_type")
      .order("team_name", { ascending: true });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching teams:", error);
    throw error;
  }
};
