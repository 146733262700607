import React from "react";
import Lineup from "./Lineup";
import "./Lineup.css";

const sampleData = [
  {
    teamName: "Newcastle",
    teamLogo: "https://media.api-sports.io/football/teams/34.png",
    opponentName: "Southampton",
    opponentLogo: "https://media.api-sports.io/football/teams/33.png",
    score: "2-1",
    username: "HowayTheLads",
    players: [
      {
        name: "Nick Pope",
        position: "GK",
        photo: "https://media.api-sports.io/football/players/18911.png",
        rating: "7.2",
        review:
          "Made an excellent second-half save from Armstrong and showed all his nous to run down the clock in the closing stages",
      },
      {
        name: "Valentino Livramento",
        position: "RB",
        photo: "https://media.api-sports.io/football/players/158694.png",
        rating: "7.3",
        review: "",
      },
      {
        name: "Fabian Schär",
        position: "CB1",
        photo: "https://media.api-sports.io/football/players/2806.png",
        rating: "4.9",
        review:
          "Should have known better – Brereton Diaz's theatrics were ridiculous, but the centre-half played right into his hands",
      },
      {
        name: "Dan Burn",
        position: "CB2",
        photo: "https://media.api-sports.io/football/players/18961.png",
        rating: "6.9",
        review:
          "Brilliant at the heart of Newcastle's back four, blocking shots, winning headers and hacking the ball away",
      },
      {
        name: "Lewis Hall",
        position: "LB",
        photo: "https://media.api-sports.io/football/players/284492.png",
        rating: "7.3",
        review:
          "Made a brilliant goalline clearance in the second half, but also had a number of shaky moments in possession",
      },
      {
        name: "Sean Longstaff",
        position: "RM",
        photo: "https://media.api-sports.io/football/players/18901.png",
        rating: "6.9",
        review:
          "A major factor in the Magpies' win – ran himself into the ground to close down Southampton's midfielders",
      },
      {
        name: "Bruno Guimarães",
        position: "CM1",
        photo: "https://media.api-sports.io/football/players/10135.png",
        rating: "7.3",
        review:
          "Started slowly, but was a major factor in the second half as he threw himself into tackles",
      },
      {
        name: "Joelinton",
        position: "CM2",
        photo: "https://media.api-sports.io/football/players/723.png",
        rating: "7.9",
        review:
          "Immense – dominated the midfield battle and set the tone for Newcastle's never-say-die performance",
      },
      {
        name: "Jacob Murphy",
        position: "LM",
        photo: "https://media.api-sports.io/football/players/19163.png",
        rating: "6.9",
        review:
          "Started fairly slowly down the right-hand side, and was the player to be sacrificed when Schar was sent off",
      },
      {
        name: "Alexander Isak",
        position: "ST1",
        photo: "https://media.api-sports.io/football/players/2864.png",
        rating: "7.3",
        review:
          "Had to drop extremely deep in the second half, but helped out his defence after setting up Joelinton's goal",
      },
      {
        name: "Anthony Gordon",
        position: "ST2",
        photo: "https://media.api-sports.io/football/players/138787.png",
        rating: "6.9",
        review:
          "Made the occasional dangerous break, but seemed to be lacking some of his usual zip and energy",
      },
    ],
  },
];

function App() {
  return (
    <div className="App">
      <Lineup data={sampleData} />
    </div>
  );
}

export default App;
