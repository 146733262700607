// src/api/players.js
import { fixtureId } from "../context/AppContext";

const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL = "https://v3.football.api-sports.io/fixtures/players";

export const fetchNbaPlayers = async (fixtureId) => {
  if (!fixtureId) {
    throw new Error("Fixture ID is required");
  }

  // Convert teamId to an integer
  const fixtureIdInt = parseInt(fixtureId, 10);

  if (isNaN(fixtureIdInt)) {
    throw new Error("Invalid fixture ID: must be a valid integer");
  }

  console.log("Fetching players for game ID:", fixtureIdInt);

  try {
    const response = await fetch(`${API_URL}?fixture=${fixtureIdInt}`, {
      method: "GET",
      headers: {
        "x-rapidapi-key": API_KEY,
        "x-rapidapi-host": "v3.football.api-sports.io",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("API Response:", data);

    if (!data.response || !Array.isArray(data.response)) {
      console.log("Unexpected API response format:", data);
      return [];
    }

    const players = data.response.map((item) => ({
      id: item.player.id,
      player_name: `${item.player.firstname} ${item.player.lastname}`,
      team_id: item.statistics[0]?.team.id,
      team_key: item.statistics[0]?.team.name,
      minutes: item.statistics[0]?.games.minutes,
    }));

    console.log("Mapped players:", players);
    return players;
  } catch (error) {
    console.error("Failed to fetch players:", error);
    return [];
  }
};

export const fetchPlayerDetails = async (playerId, fixtureId) => {
  try {
    console.log("fixtureId:", fixtureId);
    const response = await fetch(`${API_URL}?fixture=${fixtureId}`, {
      method: "GET",
      headers: {
        "x-rapidapi-key": API_KEY,
        "x-rapidapi-host": "v3.football.api-sports.io",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log("data:", data);
    const playerData = data.response.find(
      (item) => item.player.id === playerId
    );
    console.log("playerData:", playerData);
    return {
      id: playerData.player.id,
      player_name: playerData.player.name,
      team_id: playerData.statistics[0].team.id,
      team_key: playerData.statistics[0].team.name,
      position: playerData.statistics[0].games.position,
      minutes: playerData.statistics[0].games.minutes,
      photo: playerData.player.photo,
    };
  } catch (error) {
    console.error("Failed to fetch player details:", error);
    return null;
  }
};
