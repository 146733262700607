import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useParams, Link } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import Loader from "../components/Loader";
import { getPillColor } from "../utils/helpers";
import { format } from "date-fns";
import "./TeamProfile.css";

const TeamProfile = () => {
  const { teamId } = useParams();
  const { supabase } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [team, setTeam] = useState(null);
  const [matches, setMatches] = useState([]);
  const [players, setPlayers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTeamData = async () => {
      setIsLoading(true);
      try {
        // Fetch team data
        const { data: teamData, error: teamError } = await supabase
          .from("teams_table_soccer")
          .select("*")
          .eq("id", teamId)
          .single();

        if (teamError) throw teamError;

        // Fetch team average rating
        const { data: ratingData, error: ratingError } = await supabase
          .from("team_review_averages_soccer")
          .select("*")
          .eq("team_id", teamId)
          .eq("competition_name", "Overall")
          .single();

        if (ratingError) throw ratingError;

        setTeam({ ...teamData, averageRating: ratingData.average_rating });

        // Fetch completed matches
        const { data: matchesData, error: matchesError } = await supabase
          .from("games_table_soccer")
          .select("*")
          .or(`home_team_id.eq.${teamId},away_team_id.eq.${teamId}`)
          .eq("status_short", "FT")
          .order("date", { ascending: false });

        if (matchesError) throw matchesError;

        setMatches(matchesData);

        // First, get all player_ids associated with the team
        const { data: teamPlayers, error: teamPlayersError } = await supabase
          .from("player_team_associations")
          .select("player_id")
          .eq("team_id", teamId);

        if (teamPlayersError) throw teamPlayersError;

        // Then, fetch ratings for these players
        const playerIds = teamPlayers.map((player) => player.player_id);
        const { data: playersData, error: playersError } = await supabase
          .from("player_review_averages_soccer")
          .select(
            `
           player_id,
           average_rating,
           competition_name,
           players_table_soccer(player_name)
         `
          )
          .in("player_id", playerIds)
          .eq("competition_name", "Overall")
          .order("average_rating", { ascending: false });

        if (playersError) throw playersError;

        setPlayers(playersData);
      } catch (error) {
        console.error("Error fetching team data:", error);
        setError("Failed to fetch team data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeamData();
  }, [teamId, supabase]);

  const groupedMatches = useMemo(() => {
    return matches.reduce((acc, match) => {
      if (!acc[match.competition_name]) {
        acc[match.competition_name] = [];
      }
      acc[match.competition_name].push(match);
      return acc;
    }, {});
  }, [matches]);

  if (isLoading) return <Loader />;
  if (error) return <div className="error-message">{error}</div>;
  if (!team) return <div className="error-message">Team not found</div>;

  return (
    <div className="team-profile">
      <Helmet>
        <title>{team.team_name} Profile | Pundit.World</title>{" "}
        {/* {2023-11-21 19:45} Added title tag for SEO */}
        <meta
          name="description"
          content={`View the profile of ${team.team_name} on Pundit.World. Check out their matches, players, and ratings.`} // {2023-11-21 19:45} Added meta description for SEO
        />
        <link
          rel="canonical"
          href={`https://www.pundit.world/team-profile/${teamId}`} // {2023-11-21 19:45} Added canonical link for SEO
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "SportsTeam",
            name: team.team_name,
            description: `View the profile of ${team.team_name} on Pundit.World. Check out their matches, players, and ratings.`,
            url: `https://www.pundit.world/team-profile/${teamId}`,
          })}
        </script>
      </Helmet>
      <TeamHeader team={team} />
      <MatchesSection groupedMatches={groupedMatches} teamId={teamId} />
      <PlayersSection players={players} />
    </div>
  );
};

const TeamHeader = ({ team }) => (
  <header className="team-header">
    <div className="tp-team-info">
      <img src={team.team_logo} alt={team.team_name} className="tp-team-logo" />
      <h1 className="team-name">{team.team_name}</h1>
      <p className="team-competition">{team.competition_name}</p>
    </div>
    <div className="team-rating">
      <span>Season Rating</span>
      <div className={`tp-score-pill ${getPillColor(team.averageRating)}`}>
        {team.averageRating.toFixed(2)}
      </div>
    </div>
  </header>
);

const MatchesSection = ({ groupedMatches, teamId }) => (
  <section className="matches-section">
    <h2 className="tp-section-title">Matches</h2>
    {Object.keys(groupedMatches).length > 0 ? (
      Object.entries(groupedMatches).map(([competition, matches]) => (
        <div key={competition} className="competition-group">
          {/*<h3>{competition}</h3>*/}
          {matches.map((match) => (
            <MatchCard key={match.id} match={match} teamId={teamId} />
          ))}
        </div>
      ))
    ) : (
      <p>No completed matches found for this season.</p>
    )}
  </section>
);

const MatchCard = ({ match, teamId }) => {
  const isHomeTeam = match.home_team_id === teamId;
  const opponent = isHomeTeam ? match.away_team_name : match.home_team_name;
  const score = `${match.home_team_score} - ${match.away_team_score}`;

  return (
    <div className="tp-match-card">
      <div className="match-info">
        <span className="match-date">
          {format(new Date(match.date), "dd MMM yyyy")}
        </span>
        <span className="match-teams">
          {isHomeTeam
            ? `${match.home_team_name} vs ${opponent}`
            : `${opponent} vs ${match.away_team_name}`}
        </span>
        <span className="match-score">{score}</span>
      </div>
      <div className="match-actions">
        <Link to={`/match-report/${match.id}`} className="btn btn-secondary">
          Match Report
        </Link>
        <Link to={`/review/${match.id}`} className="btn btn-primary">
          Vote Now
        </Link>
      </div>
    </div>
  );
};

const PlayersSection = ({ players }) => (
  <section className="players-section">
    <h2 className="tp-section-title">Player Ratings</h2>
    {players.length > 0 ? (
      <div className="players-grid">
        {players.map((player) => (
          <PlayerCard key={player.id} player={player} />
        ))}
      </div>
    ) : (
      <p>No player ratings available for this season.</p>
    )}
  </section>
);

const PlayerCard = ({ player }) => (
  <div className="player-card">
    <img
      src={`https://media.api-sports.io/football/players/${player.player_id}.png`}
      alt={player.players_table_soccer.player_name}
      className="tp-player-headshot"
    />
    <div className="player-info">
      <Link
        to={`/player/${player.player_id}/${encodeURIComponent(
          player.players_table_soccer.player_name
        )}`}
      >
        <h4>{player.players_table_soccer.player_name}</h4>
      </Link>
    </div>
    <div className={`score-pill ${getPillColor(player.average_rating)}`}>
      {player.average_rating.toFixed(1)}
    </div>
  </div>
);

export default TeamProfile;
