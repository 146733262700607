// src/hooks/useAuth.js
import { useState, useEffect } from "react";
import supabase from "../api/supabase";

const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    console.log("Checking user");
    const checkUser = async () => {
      try {
        const {
          data: { user },
        } = await supabase.auth.getUser();
        console.log("User:", user);
        setCurrentUser(user);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    checkUser();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log("Auth state changed:", event, session);
        setCurrentUser(session?.user ?? null);
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  // {2024-09-20 - 13:10} Added email verification function
  const verifyEmailExists = async (email) => {
    try {
      const { data, error } = await supabase
        .from("profiles")
        .select("email")
        .eq("email", email)
        .single();

      if (error) {
        console.warn("Email does not exist:", email);
        return false;
      }
      console.log("Email exists:", email);
      return true;
    } catch (error) {
      console.error("Error verifying email:", error);
      return false;
    }
  };

  // {2024-09-20 - 12:08} Added Forgot Password functionality
  const sendPasswordResetEmail = async (email) => {
    try {
      const { data, error } = await supabase.auth.resetPasswordForEmail(email);
      if (error) {
        console.error("Password reset error:", error);
        throw error;
      }
      console.log("Password reset email sent to:", email);
      return data;
    } catch (error) {
      console.error("Unexpected error in password reset:", error);
      throw error;
    }
  };

  const signInWithGoogle = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: `${window.location.origin}/userprofile`,
          queryParams: {
            access_type: "offline",
            prompt: "consent",
          },
        },
      });
      if (error) {
        console.error("Google sign-in error:", error);
        throw error;
      }
      console.log("Google sign-in successful:", data.user);
    } catch (error) {
      console.error("Unexpected error during Google sign-in:", error);
    }
  };

  // src/hooks/useAuth.js
  const handleOAuthSignIn = async () => {
    try {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error getting session:", error.message);
        return;
      }

      const { user } = data.session;
      console.log("Authenticated user:", user);

      // Check if user is missing required profile fields
      const { data: profileData, error: profileError } = await supabase
        .from("profiles")
        .select("username, favorite_team_id")
        .eq("id", user.id)
        .single();

      if (profileError) {
        console.error("Error fetching profile:", profileError.message);
        return;
      }

      // If the profile is incomplete, redirect to profile completion page
      if (!profileData.username || !profileData.favorite_team_id) {
        console.log(
          "Incomplete profile detected. Redirecting to /complete-profile"
        );
        window.location.href = "/complete-profile";
      } else {
        console.log("User profile is complete.");
      }
    } catch (error) {
      console.error("Unexpected error during OAuth sign-in:", error);
    }
  };

  const signIn = async (email, password) => {
    console.log("Signing in with credentials:", { email, password });
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) {
        console.error("Sign-in error:", error);
        throw error;
      }
      console.log("Sign-in successful:", data.user);
      setCurrentUser(data.user);
    } catch (error) {
      console.error("Unexpected error during sign-in:", error);
      throw error;
    }
  };

  const signUp = async (email, password, username, favoriteTeamId) => {
    console.log("Signing up with details:", {
      email,
      password,
      username,
      favoriteTeamId,
    });

    // Validate favoriteTeamId
    if (!favoriteTeamId || isNaN(parseInt(favoriteTeamId))) {
      console.error("Invalid favoriteTeamId provided:", favoriteTeamId);
      throw new Error("Invalid favorite team selection");
    }

    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            username,
            favorite_team_id: parseInt(favoriteTeamId),
          },
        },
      });

      if (error) {
        console.error("Sign-up error:", error);
        throw error;
      }
      console.log("Sign-up successful:", data.user);

      // Check if user is created successfully
      if (data.user) {
        // Insert the user profile manually
        const { error: profileError } = await supabase.from("profiles").insert({
          id: data.user.id,
          username: username,
          favorite_team_id: parseInt(favoriteTeamId),
          registration_date: data.user.created_at,
        });

        if (profileError) {
          console.error("Profile creation error:", profileError);
          throw profileError;
        }

        console.log("Profile created successfully for user ID:", data.user.id);
        setCurrentUser(data.user);
      } else {
        console.error("User object is undefined after sign-up.");
        throw new Error("Failed to create user");
      }
    } catch (error) {
      console.error("Unexpected error during sign-up:", error);
      throw error;
    }
  };

  const signOut = async () => {
    console.log("Signing out user.");
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error("Sign-out error:", error);
        throw error;
      }
      console.log("Sign-out successful.");
      setCurrentUser(null);
    } catch (error) {
      console.error("Unexpected error during sign-out:", error);
      throw error;
    }
  };

  return {
    currentUser,
    signIn,
    signUp,
    signOut,
    signInWithGoogle,
    handleOAuthSignIn,
    sendPasswordResetEmail,
    verifyEmailExists,
  };
};

export default useAuth;
