// src/components/EAPlayerRatingTable.js
import React, { useState, useEffect } from "react";
import { useAppContext } from "../context/AppContext";
import { Helmet } from "react-helmet";
import supabase from "../api/supabase";
import "./EAPlayerRatingTable.css";

const EAPlayerRatingTable = () => {
  const { currentUser } = useAppContext();
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [playerRatings, setPlayerRatings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  }); // {date - time} - 2023-11-21 15:00 - Added state for sorting configuration

  // Add this function near the top of your component file
  const mapPosition = (positionLetter) => {
    const positionMap = {
      G: "Goalkeeper",
      D: "Defender",
      M: "Midfielder",
      F: "Forward",
    };
    return positionMap[positionLetter] || positionLetter;
  };

  // {date - time} - 2023-11-21 14:35 - Added useEffect for fetching teams
  useEffect(() => {
    fetchTeams();
  }, []);

  // {date - time} - 2023-11-21 14:36 - Added useEffect for fetching player ratings
  useEffect(() => {
    if (selectedTeam) {
      fetchPlayerRatings(selectedTeam);
    }
  }, [selectedTeam]);

  const fetchTeams = async () => {
    setIsLoading(true);
    try {
      // First, fetch all distinct team_ids from ea_player_rating_averages
      const { data: ratedTeamIds, error: ratedError } = await supabase
        .from("ea_player_rating_averages")
        .select("team_id", { distinct: true });

      console.log(ratedTeamIds);

      if (ratedError) throw ratedError;

      const { data, error } = await supabase
        .from("teams_table_soccer")
        .select("id, team_name")
        .order("team_name")
        .in(
          "id",
          ratedTeamIds.map((team) => team.team_id)
        );

      console.log(data);
      if (error) throw error;

      const ratedTeams = data.filter((team) => team.id != 0);

      console.log(ratedTeams);
      setTeams(ratedTeams);
    } catch (error) {
      setError("Failed to fetch teams");
      console.error("Error fetching teams:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPlayerRatings = async (teamId) => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from("ea_player_rating_averages")
        .select(
          `
          *,
          players_table_soccer!player_id (
            player_name,
            position,
            photo
          )
        `
        )
        .eq("team_id", teamId)
        .order("average_overall_rating", { ascending: false });

      if (error) throw error;

      setPlayerRatings(data);
    } catch (error) {
      setError("Failed to fetch player ratings");
      console.error("Error fetching player ratings:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // {date - time} - 2023-11-21 14:40 - Added function to get color based on rating
  const getRatingColor = (rating) => {
    if (rating >= 80) return "green";
    if (rating >= 70) return "yellow";
    return "orange";
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // {date - time} - 2023-11-21 15:10 - Added sorting logic to player ratings
  const sortedPlayerRatings = React.useMemo(() => {
    let sortableItems = [...playerRatings];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [playerRatings, sortConfig]);

  console.log(playerRatings);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="ea-player-rating-table">
      <Helmet>
        <title>EAFC 25 Community Player Ratings</title>{" "}
        {/* {2023-11-21 16:05} Add Helmet for SEO */}
        <meta
          name="description"
          content="View and sort player ratings for EAFC 25 community. Select a team to see player ratings and sort by position, overall rating, or potential."
        />{" "}
        {/* {2023-11-21 16:05} Add meta description */}
      </Helmet>
      <h3 className="ea-player-rating-table-title">
        EAFC 25 Community Player Ratings
      </h3>
      <select
        value={selectedTeam}
        onChange={(e) => setSelectedTeam(e.target.value)}
        className="team-select"
      >
        <option value="">Select a Team</option>
        {teams.map((team) => (
          <option key={team.id} value={team.id}>
            {team.team_name}
          </option>
        ))}
      </select>

      {sortedPlayerRatings.length > 0 && (
        <table>
          <thead>
            <tr className="header-row">
              <th>Player</th>
              <th
                className="position-column"
                onClick={() => handleSort("players_table_soccer.position")}
              >
                Position
              </th>{" "}
              {/* {date - time} - 2023-11-21 15:15 - Added onClick for sorting by position */}
              <th
                className="rating-column"
                onClick={() => handleSort("average_overall_rating")}
              >
                Overall Rating
              </th>{" "}
              {/* {date - time} - 2023-11-21 15:15 - Added onClick for sorting by overall rating */}
              <th
                className="rating-column"
                onClick={() => handleSort("average_potential")}
              >
                Potential
              </th>{" "}
              {/* {date - time} - 2023-11-21 15:15 - Added onClick for sorting by potential */}
            </tr>
          </thead>
          <tbody>
            {sortedPlayerRatings.map((rating) => (
              <tr key={rating.player_id}>
                <td className="ea-player-name" data-label="Player">
                  <img
                    src={rating.players_table_soccer.photo}
                    alt={rating.players_table_soccer.player_name}
                    className="ea-player-photo"
                  />
                  {rating.players_table_soccer.player_name}
                </td>
                <td className="position" data-label="Position">
                  {mapPosition(rating.players_table_soccer.position)}
                </td>
                <td className="rating-column" data-label="Overall Rating">
                  <span
                    className={`rating ${getRatingColor(
                      rating.average_overall_rating
                    )}`}
                  >
                    {Math.round(rating.average_overall_rating)}
                  </span>
                </td>
                <td className="rating-column" data-label="Potential">
                  <span
                    className={`rating ${getRatingColor(
                      rating.average_potential
                    )}`}
                  >
                    {Math.round(rating.average_potential)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default EAPlayerRatingTable;
