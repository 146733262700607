import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import Loader from "../components/Loader";
import "./Teams.css";

function Teams() {
  const [teams, setTeams] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [teamsPerPage] = useState(20);
  const { isLoading, supabase } = useAppContext();

  useEffect(() => {
    const fetchTeams = async () => {
      const { data, error } = await supabase
        .from("teams_table_soccer")
        .select("*")
        .order("team_name", { ascending: true });

      if (error) {
        console.error("Error fetching teams:", error);
        return;
      }

      setTeams(data);
    };

    fetchTeams();
  }, [supabase]);

  // Get current teams
  const indexOfLastTeam = currentPage * teamsPerPage;
  const indexOfFirstTeam = indexOfLastTeam - teamsPerPage;
  const currentTeams = teams.slice(indexOfFirstTeam, indexOfLastTeam);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (isLoading) return <Loader />;

  return (
    <div className="teams-page">
      <h1>Teams</h1>
      <div className="teams-grid">
        {currentTeams.map((team) => (
          <Link
            key={team.id}
            to={`/team/${team.id}/${encodeURIComponent(team.team_name)}`}
            className="team-card"
          >
            <img
              src={team.team_logo}
              alt={`${team.team_name} logo`}
              className="team-logo"
            />
            <h2>{team.team_name}</h2>
          </Link>
        ))}
      </div>
      <div className="pagination">
        {Array.from(
          { length: Math.ceil(teams.length / teamsPerPage) },
          (_, i) => (
            <button
              key={i}
              onClick={() => paginate(i + 1)}
              className={currentPage === i + 1 ? "active" : ""}
            >
              {i + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
}

export default Teams;

// {date - time} - 2023-05-20 16:30 - Created Teams component
// {date - time} - 2023-05-20 16:45 - Implemented data fetching and rendering
// {date - time} - 2023-05-20 17:00 - Added pagination and styling
