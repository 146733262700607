// src/components/AuthForm.js
import React, { useState, useEffect } from "react";
import { useAppContext } from "../context/AppContext";
import useAuth from "../hooks/useAuth";

const AuthForm = () => {
  const { teams, setShowAuthForm, authErrorMessage, setAuthErrorMessage } =
    useAppContext();

  const {
    signIn,
    signUp,
    signInWithGoogle,
    sendPasswordResetEmail,
    verifyEmailExists, // {2024-09-20 - 14:18} Destructure verifyEmailExists from useAuth
  } = useAuth(); // {2024-09-20 - 14:19} Use useAuth hook instead of useAppContext

  const [authMode, setAuthMode] = useState("signin");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [favoriteTeamId, setFavoriteTeamId] = useState("");
  const [error, setError] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  // {2024-09-20 - 13:04} Handles opening the Forgot Password modal
  const handleForgotPassword = () => {
    setShowForgotPasswordModal(true);
  };

  // {2024-09-20 - 13:05} Handles the password reset logic
  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      // {2024-09-20 - 13:06} Verifying if the email exists
      const emailExists = await verifyEmailExists(email);
      if (emailExists) {
        await sendPasswordResetEmail(email);
        alert("Password reset email sent. Please check your inbox.");
        setShowForgotPasswordModal(false); // Close the modal after success
      } else {
        setError("We have no record of that email address.");
      }
    } catch (err) {
      console.error("Password reset error:", err);
      setError("Failed to send password reset email.");
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      setShowAuthForm(false);
    } catch (error) {
      console.error("Google Sign-In error:", error.message);
    }
  };

  useEffect(() => {
    console.log("Teams in AuthForm:", teams);
  }, [teams]);

  useEffect(() => {
    // Set the error message from authErrorMessage if it exists
    if (authErrorMessage) {
      setError(authErrorMessage);
      setAuthErrorMessage(""); // Clear the global error message
    }
  }, [authErrorMessage, setAuthErrorMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      if (authMode === "signin") {
        await signIn(email, password);
      } else {
        if (!favoriteTeamId) {
          setError("Please select a favorite team");
          return;
        }
        const favoriteTeamIdNumber = parseInt(favoriteTeamId, 10);
        if (isNaN(favoriteTeamIdNumber)) {
          setError("Invalid team selection");
          return;
        }
        await signUp(email, password, username, favoriteTeamIdNumber);
      }
      setShowAuthForm(false);
    } catch (err) {
      console.error("Auth error:", err);
      setError(err.message);
    }
  };

  const handleClose = () => {
    setShowAuthForm(false);
    setAuthErrorMessage(""); // Clear the global error message when closing
  };

  // {2024-09-20 - 13:31} Prevent event bubbling by stopping propagation on modal
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="auth-overlay" onClick={handleClose}>
      <div className="auth-form-container" onClick={handleModalClick}>
        <div className="auth-form-header">
          <h2
            className={authMode === "signin" ? "active" : ""}
            onClick={() => setAuthMode("signin")}
          >
            Sign In
          </h2>
          <h2
            className={authMode === "signup" ? "active" : ""}
            onClick={() => setAuthMode("signup")}
          >
            Register
          </h2>
        </div>
        {error && <p className="auth-error">{error}</p>}
        <form className="auth-form" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {/* {2024-09-20 - 12:04} Added Forgot Password button */}
          {authMode === "signin" && (
            <p className="forgot-password" onClick={handleForgotPassword}>
              Forgot Password?
            </p>
          )}
          {authMode === "signup" && (
            <>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <select
                value={favoriteTeamId}
                onChange={(e) => setFavoriteTeamId(e.target.value)}
                required
              >
                <option value="">Select Favorite Team</option>
                {teams.map((team) => (
                  <option key={team.id} value={team.id.toString()}>
                    {team.team_name}
                  </option>
                ))}
              </select>
            </>
          )}
          <button type="submit">
            {authMode === "signin" ? "Sign In" : "Register"}
          </button>
          <button
            className="login-with-google-btn"
            onClick={handleGoogleSignIn}
          >
            {authMode === "signin"
              ? "Sign In With Google"
              : "Register With Google"}
          </button>
        </form>
      </div>
      {/* {2024-09-20 - 13:07} Modal for Forgot Password */}
      {showForgotPasswordModal && (
        <div className="forgot-password-modal" onClick={handleModalClick}>
          <div className="forgot-password-container" onClick={handleModalClick}>
            <h2>Please provide your email.</h2>
            <form onSubmit={handleForgotPasswordSubmit}>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit">Reset Password</button>
              {error && <p className="auth-error">{error}</p>}
            </form>
            <button onClick={() => setShowForgotPasswordModal(false)}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthForm;
