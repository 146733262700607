import React from "react";
import { Helmet } from "react-helmet"; // {2023-11-21 17:45} Import Helmet for SEO

const TermsOfUse = () => {
  return (
    <div className="terms-container">
      <Helmet>
        <title>Terms of Use | Pundit.World</title>{" "}
        {/* {2023-11-21 17:45} Added title tag for SEO */}
        <meta
          name="description"
          content="Read the Terms of Use for Pundit.World. By using our website and services, you agree to be bound by these terms." // {2023-11-21 17:45} Added meta description for SEO
        />
        <link
          rel="canonical"
          href="https://www.pundit.world/terms-of-use" // {2023-11-21 17:45} Added canonical link for SEO
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "Terms of Use",
            description:
              "Read the Terms of Use for Pundit.World. By using our website and services, you agree to be bound by these terms.",
            url: "https://www.pundit.world/terms-of-use",
          })}
        </script>
      </Helmet>
      <h1>Terms of Use for Pundit.World</h1>
      <p>
        These Terms of Use ("Terms") apply to your use of Pundit.World's website
        and services. Please read these Terms carefully; by using Pundit.World,
        you agree to be bound by them.
      </p>

      <h2>1. Content and Intellectual Property</h2>
      <p>
        All content on Pundit.World, including but not limited to text,
        graphics, logos, and data, is protected by copyright and other
        intellectual property laws. Pundit.World grants you a personal,
        non-commercial license to use our services as permitted by these Terms.
      </p>

      <h2>2. User Content and Behavior</h2>
      <ul>
        <li>
          <strong>Your Content:</strong> You are responsible for any content you
          post on Pundit.World. By posting, you grant Pundit.World a
          non-exclusive, royalty-free license to use, modify, and display your
          content.
        </li>
        <li>
          <strong>Prohibited Conduct:</strong> You agree not to use Pundit.World
          for any unlawful purposes or in ways that violate these Terms. This
          includes posting offensive, harmful, or infringing content.
        </li>
      </ul>

      <h2>3. Data Usage and Scraping</h2>
      <p>
        Unauthorized use, reproduction, or redistribution of any content from
        Pundit.World is strictly prohibited. This includes data scraping, use of
        automated systems, or bulk retrieval of data without express written
        consent.
      </p>

      <h2>4. Privacy and Data Protection</h2>
      <p>
        Our Privacy Policy, available at https://www.pundit.world/privacy,
        explains how we collect, use, and protect your data. By using
        Pundit.World, you consent to the practices described in the Privacy
        Policy.
      </p>

      <h2>5. User Rights and Account Management</h2>
      <ul>
        <li>
          <strong>Account Deletion:</strong> You can delete your account and
          associated data using the delete account button on your User Profile
          page.
        </li>
        <li>
          <strong>Data Opt-Out:</strong> You may opt out of certain data
          collection practices as described in our Privacy Policy.
        </li>
      </ul>

      <h2>6. Disclaimers and Limitation of Liability</h2>
      <p>
        Pundit.World is provided "as is" without any warranties. To the fullest
        extent permitted by law, Pundit.World shall not be liable for any
        indirect, incidental, special, or consequential damages resulting from
        your use or inability to use our services.
      </p>

      <h2>7. Changes to Services and Terms</h2>
      <p>
        We may modify our services or these Terms at any time. We will notify
        users of significant changes via email. Your continued use of
        Pundit.World after such changes constitutes acceptance of the modified
        Terms.
      </p>

      <h2>8. Governing Law and Dispute Resolution</h2>
      <p>
        These Terms are governed by the laws of the jurisdiction where
        Pundit.World is based. Any disputes shall be resolved through good-faith
        negotiations or, if necessary, through appropriate legal channels in our
        jurisdiction.
      </p>

      <h2>9. Children's Use</h2>
      <p>
        While we do not have specific age restrictions, parents and guardians
        should supervise their children's use of Pundit.World.
      </p>

      <h2>10. Contact Information</h2>
      <p>
        If you have any questions or concerns about these Terms, please contact
        us at{" "}
        <a href="mailto:pundit.sports.home+Terms@gmail.com">
          pundit.sports.home+Terms@gmail.com
        </a>
        .
      </p>
    </div>
  );
};

export default TermsOfUse;
