import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet"; // {2024-03-21 - 12:00} Import Helmet for SEO
import { useAppContext } from "../context/AppContext";
import supabase from "../api/supabase";
import "./EAPlayerRatingForm.css";
import useAuth from "../hooks/useAuth"; // {2024-03-21 - 11:30} Import useAuth hook
import AuthForm from "./AuthForm"; // {2024-03-21 - 11:30} Import AuthForm component

const PlayerRatingForm = () => {
  const { currentUser } = useAuth(); // {2024-03-21 - 11:31} Use useAuth hook
  const { showAuthForm, setShowAuthForm } = useAppContext(); // {2024-03-21 - 11:31} Get showAuthForm and setShowAuthForm from context
  const [leagues, setLeagues] = useState([]);
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [ratings, setRatings] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showAuthPrompt, setShowAuthPrompt] = useState(false); // {2024-03-21 - 10:17} Add state for auth prompt

  // Add this function near the top of your component file
  const mapPosition = (positionLetter) => {
    const positionMap = {
      G: "Goalkeeper",
      D: "Defender",
      M: "Midfielder",
      F: "Forward",
    };
    return positionMap[positionLetter] || positionLetter;
  };

  useEffect(() => {
    fetchLeagues();
  }, []);

  useEffect(() => {
    if (selectedLeague) {
      fetchTeams(selectedLeague);
    }
  }, [selectedLeague]);

  useEffect(() => {
    if (selectedTeam) {
      fetchPlayers(selectedTeam);
    }
  }, [selectedTeam]);

  // {2024-03-21 - 11:32} Add useEffect to check user authentication
  useEffect(() => {
    if (!currentUser) {
      setShowAuthPrompt(true);
    } else {
      setShowAuthPrompt(false);
    }
  }, [currentUser]);

  const handleAuthPrompt = () => {
    setShowAuthForm(true);
    setShowAuthPrompt(false);
  };

  const fetchLeagues = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://v3.football.api-sports.io/leagues",
        {
          headers: {
            "x-rapidapi-host": "v3.football.api-sports.io",
            "x-rapidapi-key": process.env.REACT_APP_API_KEY,
          },
        }
      );
      const data = await response.json();
      const leagueIds = [39, 140, 253];
      const sortedLeagues = data.response
        .filter((league) => leagueIds.includes(league.league.id))
        .sort((a, b) => a.league.name.localeCompare(b.league.name));
      setLeagues(sortedLeagues);
    } catch (error) {
      setError("Failed to fetch leagues");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTeams = async (leagueId) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://v3.football.api-sports.io/teams?league=${leagueId}&season=2024`,
        {
          headers: {
            "x-rapidapi-host": "v3.football.api-sports.io",
            "x-rapidapi-key": process.env.REACT_APP_API_KEY,
          },
        }
      );
      const data = await response.json();
      const sortedTeams = data.response.sort((a, b) =>
        a.team.name.localeCompare(b.team.name)
      );
      setTeams(sortedTeams);
    } catch (error) {
      setError("Failed to fetch teams");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPlayers = async (teamId) => {
    setIsLoading(true);
    try {
      // {2024-03-20 - 15:30} Query player_team_associations table
      const { data: playerIds, error: associationError } = await supabase
        .from("player_team_associations")
        .select("player_id")
        .eq("team_id", teamId);

      if (associationError) throw associationError;

      // {2024-03-20 - 15:31} Query players_table_soccer table
      const { data: playerData, error: playerError } = await supabase
        .from("players_table_soccer")
        .select("id, player_name, position, photo")
        .in(
          "id",
          playerIds.map((p) => p.player_id)
        );

      if (playerError) throw playerError;

      // {2024-03-20 - 15:32} Map player data to the required format
      const filteredPlayers = playerData.map((player) => ({
        id: player.id,
        name: player.player_name,
        position: mapPosition(player.position),
        photo: player.photo,
      }));
      console.log(filteredPlayers);

      const positionOrder = ["Forward", "Midfielder", "Defender", "Goalkeeper"];
      const sortedPlayers = filteredPlayers.sort((a, b) => {
        const posA = positionOrder.indexOf(a.position.split(" ")[0]);
        const posB = positionOrder.indexOf(b.position.split(" ")[0]);
        if (posA !== posB) return posA - posB;
        return a.name.localeCompare(b.name);
      });
      setPlayers(sortedPlayers);
    } catch (error) {
      console.error("Error fetching players:", error);
      setError("Failed to fetch players");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRatingChange = (playerId, field, value) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [playerId]: {
        ...prevRatings[playerId],
        [field]: value,
      },
    }));
  };

  const insertPlayerIfNotExists = async (player) => {
    const { id, name, position, photo } = player;

    // Check if player exists in players_table_soccer
    const { data: existingPlayer, error: playerCheckError } = await supabase
      .from("players_table_soccer")
      .select("id")
      .eq("id", id)
      .single();

    if (playerCheckError && playerCheckError.code !== "PGRST116") {
      console.error("Error checking player existence:", playerCheckError);
      return;
    }

    // If player doesn't exist, insert into players_table_soccer
    if (!existingPlayer) {
      const { error: insertPlayerError } = await supabase
        .from("players_table_soccer")
        .insert({ id, player_name: name, position, photo });

      if (insertPlayerError) {
        console.error("Error inserting player:", insertPlayerError);
        return;
      }
      console.log("Player inserted successfully:", name);
    }

    // Check if player-team association exists
    const { data: existingAssociation, error: associationCheckError } =
      await supabase
        .from("player_team_associations")
        .select("id")
        .eq("player_id", id)
        .eq("team_id", selectedTeam)
        .single();

    if (associationCheckError && associationCheckError.code !== "PGRST116") {
      console.error(
        "Error checking player-team association:",
        associationCheckError
      );
      return;
    }

    // If association doesn't exist, insert into player_team_associations
    if (!existingAssociation) {
      const { error: insertAssociationError } = await supabase
        .from("player_team_associations")
        .insert({ player_id: id, team_id: selectedTeam });

      if (insertAssociationError) {
        console.error(
          "Error inserting player-team association:",
          insertAssociationError
        );
        return;
      }
      console.log(
        "Player-team association inserted successfully:",
        name,
        selectedTeam
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentUser) {
      setError("You must be logged in to submit ratings");
      return;
    }

    setIsLoading(true);
    try {
      // Call insertPlayerIfNotExists for each player before submitting ratings
      for (const player of players) {
        await insertPlayerIfNotExists(player);
      }

      const ratingsToSubmit = Object.entries(ratings)
        .filter(([_, rating]) => rating.overall || rating.potential) // Filter out blank ratings
        .map(([playerId, rating]) => ({
          league_id: parseInt(selectedLeague),
          team_id: parseInt(selectedTeam),
          player_id: parseInt(playerId),
          username: currentUser?.user_metadata?.username,
          favorite_team_id: currentUser?.user_metadata?.favorite_team_id,
          overall_rating: rating.overall ? parseFloat(rating.overall) : null,
          potential: rating.potential ? parseFloat(rating.potential) : null,
        }));

      if (ratingsToSubmit.length === 0) {
        setError("No ratings to submit. Please rate at least one player.");
        setIsLoading(false);
        return;
      }

      console.log("Data to submit:", ratingsToSubmit); // Log the data for debugging

      const { data, error } = await supabase
        .from("ea_player_ratings")
        .insert(ratingsToSubmit);

      if (error) {
        console.error("Supabase error:", error);
        throw error;
      }

      setRatings({});
      alert("Ratings submitted successfully!");
    } catch (error) {
      console.error("Detailed error:", error);
      setError(`Failed to submit ratings: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const getPillColor = (rating) => {
    if (rating >= 80) return "green-pill";
    if (rating >= 50) return "orange-pill";
    if (rating >= 1) return "red-pill";
    return "white-pill";
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // {2024-03-21 - 10:20} Add conditional rendering for auth prompt
  if (showAuthPrompt) {
    return (
      <div className="auth-prompt">
        <h2 className="auth-prompt-text">
          Please Sign-In or Register to use the EAFC 25 Player Ratings Form
        </h2>
        <button onClick={handleAuthPrompt} className="ea-add-review-button">
          Sign In / Register
        </button>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="player-rating-form">
      <Helmet>
        <title>EAFC 25 Player Ratings Form</title>{" "}
        {/* {2024-03-21 - 12:05} Add Helmet for SEO */}
        <meta
          name="description"
          content="Rate your favorite players in the EAFC 25 Player Ratings Form. Select a league, team, and rate players based on their performance."
        />{" "}
        {/* {2024-03-21 - 12:05} Add meta description */}
      </Helmet>
      <h2 className="form-title">EAFC 25 Player Ratings Form</h2>
      <div className="form-header">
        <select
          value={selectedLeague}
          onChange={(e) => setSelectedLeague(e.target.value)}
        >
          <option value="">Select League</option>
          {leagues.map((league) => (
            <option key={league.league.id} value={league.league.id}>
              {league.league.name}
            </option>
          ))}
        </select>

        <select
          value={selectedTeam}
          onChange={(e) => setSelectedTeam(e.target.value)}
        >
          <option value="">Select Team</option>
          {teams.map((team) => (
            <option key={team.team.id} value={team.team.id}>
              {team.team.name}
            </option>
          ))}
        </select>
        <p className="form-instructions">
          Rate as many or as few players as you want! You can only submit once.
        </p>
      </div>

      {players.length > 0 && (
        <table className="ea-player-ratings-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Overall</th>
              <th>Potential</th>
            </tr>
          </thead>
          <tbody>
            {players.map((player) => (
              <tr key={player.id}>
                <td className="ea-player-info">
                  <img
                    src={player.photo}
                    alt={player.name}
                    className="player-photo"
                  />
                  <div className="player-details">
                    <span className="ea-player-name">{player.name}</span>
                    <div className="player-position">
                      <span>{player.position}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <input
                    type="number"
                    min="1"
                    max="99"
                    value={ratings[player.id]?.overall || ""}
                    onChange={(e) =>
                      handleRatingChange(player.id, "overall", e.target.value)
                    }
                    className={`rating-input ${getPillColor(
                      ratings[player.id]?.overall
                    )}`}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    min="1"
                    max="99"
                    value={ratings[player.id]?.potential || ""}
                    onChange={(e) =>
                      handleRatingChange(player.id, "potential", e.target.value)
                    }
                    className={`rating-input ${getPillColor(
                      ratings[player.id]?.potential
                    )}`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <button type="submit" disabled={isLoading || !currentUser}>
        Submit Ratings
      </button>
    </form>
  );
};

export default PlayerRatingForm;
