// src/AppContent.js
import React, { useEffect, lazy, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useAppContext } from "./context/AppContext";
import ReactGA from "react-ga4";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import AuthForm from "./components/AuthForm";
import "./styles/global.css";
import Lineup from "./components/lineup_a";
import Privacy from "./components/Privacy";
import Teams from "./pages/Teams";
import TeamRatingsPage from "./pages/TeamRatingsPage";
import TeamProfile from "./components/TeamProfile";
import EAPlayerRatingForm from "./components/EAPlayerRatingForm";
import EAPlayerRatingTable from "./components/EAPlayerRatingTable";
import TermsOfUse from "./components/Terms";

const HomePage = lazy(() => import("./pages/HomePage"));
const PlayerProfilePage = lazy(() => import("./pages/PlayerProfilePage"));
const UserProfilePage = lazy(() => import("./pages/UserProfilePage"));
const OtherUserProfilePage = lazy(() => import("./pages/OtherUserProfilePage"));
const ReviewFormPage = lazy(() => import("./pages/ReviewFormPage"));
const PlayerRatingsPage = lazy(() => import("./pages/PlayerRatingsPage"));
const Matches = lazy(() => import("./components/Matches"));
const TeamFavoritesPage = lazy(() => import("./pages/TeamFavoritesPage"));
const FunniestUsersPage = lazy(() => import("./pages/FunniestUsersPage"));
const InsightfulUsersPage = lazy(() => import("./pages/InsightfulUsersPage"));
const ProlificUsersPage = lazy(() => import("./pages/ProlificUsersPage"));
const MatchReport = lazy(() => import("./pages/matchReport"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const ReviewPage = lazy(() => import("./components/ReviewPage"));
const CompleteProfile = lazy(() => import("./pages/CompleteProfile"));
const AppContent = () => {
  const { showAuthForm } = useAppContext();
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);
  return (
    <>
      <Header />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/matches" element={<Matches />} />
          <Route
            path="/player/:playerId/:playerName"
            element={<PlayerProfilePage />}
          />
          <Route path="/userprofile" element={<UserProfilePage />} />
          <Route path="/user/:username" element={<OtherUserProfilePage />} />
          <Route path="/player-ratings" element={<PlayerRatingsPage />} />
          <Route path="/review/:gameId" element={<ReviewFormPage />} />
          <Route path="/team-favorites" element={<TeamFavoritesPage />} />
          <Route path="/funniest-users" element={<FunniestUsersPage />} />
          <Route path="/insightful-users" element={<InsightfulUsersPage />} />
          <Route path="/prolific-users" element={<ProlificUsersPage />} />
          <Route path="/match-report/:fixtureId" element={<MatchReport />} />
          <Route
            path="/EA-FC25-player-ratings-form"
            element={<EAPlayerRatingForm />}
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/lineup" element={<Lineup />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/team-ratings" element={<TeamRatingsPage />} />
          <Route path="/team/:teamId/:teamName" element={<TeamProfile />} />
          <Route path="/team-ratings/:teamId" element={<TeamRatingsPage />} />
          <Route
            path="/EA-FC25-player-ratings"
            element={<EAPlayerRatingTable />}
          />
          <Route path="/review/view/:reviewUrlId" element={<ReviewPage />} />
          <Route path="/terms" element={<TermsOfUse />} />
        </Routes>
      </Suspense>
      <Footer />
      {showAuthForm && <AuthForm />}
    </>
  );
};

export default AppContent;
